<template>
  <div class="home">

    <!-- 广告 -->
    <div class="F-banner">
        <div id="carousel-example-generic_1" class="carousel slide" data-ride="carousel">
            
            <div class="carousel-inner" role="listbox">
                <div :class="index === 0 ? 'item active' : 'item'" v-for="(item, index) in homeBannerList" :key="index" @click="handleDetail(item.url)">
                    <img :src="item.content" alt="">
                </div>
            </div>

            <a href="#carousel-example-generic_1" role="button" data-slide="prev">
                <span class="F-glyphicon-chevron F-left-banner">
                    <img class="F-banner-ico" src="../assets/images/left-banner.png" alt="">
                </span>
            </a>
            <a href="#carousel-example-generic_1" role="button" data-slide="next">
                <span class="F-glyphicon-chevron F-right-banner" aria-hidden="true">
                    <img class="F-banner-ico" src="../assets/images/right-banner.png" alt="">
                </span>
            </a>
        </div>

        <div class="F-calendar hidden-xs hidden-sm">
            <div class="F-in-data">
                <div class="F-data-text">
                    <img @click="reductionBtn" src="../assets/images/date-left.png" alt="">
                    <span id="years">{{years}}</span>
                    <img @click="addBtn" src="../assets/images/date-right.png" alt="">
                </div>
            </div>
            <div class="F-ri-data">
                <ul id="li-datas">
                    <li v-for="(item, index) in dateList" :key="index" :class="currentDate === item ? 'fl F-active' : 'fl' ">{{item}}</li>
                    <li class="clearfix"></li>
                </ul>
            </div>
        </div>
    </div>

    <!-- 学校新闻 -->
    <div class="F-news">
        <img class="F-book hidden-xs" src="../assets/images/book.png" alt="">
        <img class="F-pattern hidden-xs" src="../assets/images/pattern.png" alt="">

        <div class="F-news-box">
            <p class="F-tab-home">学校新闻</p>
            <p class="F-san"><img src="../assets/images/sanj.png" alt=""></p>
            <a href="/list-1">
                <p class="F-all">VIEW ALL</p>
            </a>
        </div>

        <div class="F-banner-new">
            <div id="carousel-example-generic_2" class="carousel slide" data-ride="carousel">
                <ol class="carousel-indicators">
                    <li data-target="#carousel-example-generic_2" data-slide-to="0" class="active"></li>
                    <li data-target="#carousel-example-generic_2" data-slide-to="1"></li>
                </ol>

                <div class="carousel-inner" role="listbox">
                    <div :class="index === 0 ? 'item active' : 'item'" v-for="(item, index) in newsList" :key="index">
                        <div class="row">
                            <div class="col-sm-4 col-xs-4" v-for="(subItem, subndex) in item" :key="subndex"  @click="handleDetail(subItem.linkUrl)">
                                <div class="F-data">
                                    <p class="F-image"><img :src="subItem.images + '?x-oss-process=style/370_260'" alt=""></p>
                                    <div class="F-text">
                                        <div class="F-name">{{ subItem.title }}</div>
                                        <p class="F-date">
                                            <img src="../assets/images/date-ico.png" alt=""><span>{{ formatDate(subItem.addTime) }}</span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <a href="#carousel-example-generic_2" role="button" data-slide="prev">
                    <span class="F-glyphicon-chevron F-glyphicon-left">
                        <img src="../assets/images/left-home.png" alt="">
                    </span>
                </a>
                <a href="#carousel-example-generic_2" style="right: 0; left: auto;" role="button" data-slide="next">
                    <span class="F-glyphicon-chevron F-glyphicon-right" aria-hidden="true">
                        <img src="../assets/images/right-home.png" alt="">
                    </span>
                </a>
            </div>
        </div>
    </div>

    <div class="F-activity">
        <div class="F-activity-tab">
            <p>校园活动</p>
            <p><img src="../assets/images/sanj.png" alt=""></p>
        </div>
        <div class="container">
            <div class="row">
                <div class="col-lg-3 col-sm-6 col-xs-12" v-for="(item, index) in activeList" :key="index" @click="handleDetail(item.linkUrl)">
                    <p class="F-image"><img :src="item.images + '?x-oss-process=style/260_260'" alt=""></p>
                    <p class="F-text">{{ item.title }}</p>
                </div>
            </div>
        </div>
    </div>

    <div class="F-introduce">
        <img class="F-me hidden-xs" src="../assets/images/me.png" alt="">
        <p class="F-tab-home">衣食住行在海德</p>
        <div class="container">
            <div class="row">
                <div class="col-sm-6 col-md-7 col-xs-12">
                    <div class="F-video">
                        <div v-html="homeStudyList1.content"></div>
                    </div>
                </div>
                <div class="col-sm-6 col-md-5 col-xs-12">
                    <div class="F-message" :style="'background:url(' + homeStudyList2.content + ') no-repeat 100%; background-size:cover;display:flex;justify-content: center;align-items:center;'" >
                    </div>
                </div>
            </div>
        </div>

        <div class="F-subjects-box">
            <div class="container">
                <div class="row">
                    <div class="col-sm-4 col-xs-12">
                        <div class="F-subjects" :style="'background:url(' + homeStudyList3.content + ') no-repeat 100%; background-size:cover;display:flex;justify-content: center;align-items:center;'" >
                        </div>
                    </div>
                    <div class="col-sm-4 col-xs-12">
                        <div class="F-subjects" :style="'background:url(' + homeStudyList4.content + ') no-repeat 100%; background-size:cover;display:flex;justify-content: center;align-items:center;'" >
                        </div>
                    </div>
                    <div class="col-sm-4 col-xs-12">
                        <div class="F-subjects" :style="'background:url(' + homeStudyList0.content + ') no-repeat 100%; background-size:cover;display:flex;justify-content: center;align-items:center;'" >
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="F-environment">
        <p class="F-text">学校环境</p>
        <p class="F-back"><img src="../assets/images/sanj.png" alt=""></p>

        <!-- pc轮播图 -->
        <el-carousel :interval="4000" type="card" class="hidden-xs carousel" height="380px">
          <el-carousel-item v-for="(item, index) in schoolBannerList" :key="index">
            <div class="environmentImg" @click="handleDetail(item.url)"><img :src="item.content"></div>
          </el-carousel-item>
        </el-carousel>
        <!-- h5轮播图 -->
        <div class="visible-xs">
            <div id="carousel-example-generic_3" class="carousel slide" data-ride="carousel">
                <ol class="carousel-indicators">
                    <li v-for="(item, index) in schoolBannerList" :key="index" data-target="#carousel-example-generic_3" :data-slide-to="index" :class="currentIndex === index ? 'active' : ''"></li>
                </ol>
                <div class="carousel-inner" role="listbox">
                    <div v-for="(item, index) in schoolBannerList" :key="index" :class="currentIndex === index ? 'item active' : 'item'" @click="handleDetail(item.url)">
                        <img style="width:375px;height:200px;" :src="item.content">
                    </div>
                </div>
            </div>
        </div>
    </div>

  </div>
</template>


<script>
import { getAds, getPage } from '@/api/index';
import '../components/jquery.skidder/jquery.skidder';
export default {
  name: 'Home',
  components: {
  },
  data() {
    return {
        years: undefined,
        date: undefined,
        currentIndex: 0,   //默认显示图片
        //   当月日期数组
        dateList: [],
        // 当前天
        currentDate: undefined,
        // 广告列表
        homeBannerList: [],
        // 新闻列表
        newsList: [],
        // 校园活动列表
        activeList: [],
        // 学习
        homeStudyList: [],
        homeStudyList0: {},
        homeStudyList1: {},
        homeStudyList2: {},
        homeStudyList3: {},
        homeStudyList4: {},
        // 学校环境
        schoolBannerList: []
    }
  },
  watch: {
  },
  beforeCreated() {
  },
  created() {
    this.getHomeList();
    this.getList();
  },
  methods: {
    getList() {
      let mydate = new Date();
      this.date = mydate.getFullYear() + '-' + (mydate.getMonth() + 1) + '-' + mydate.getDate();
      let str = "" + mydate.getFullYear() + "年";
      str += (mydate.getMonth() + 1) + "月";
      this.getday(mydate.getFullYear(), mydate.getMonth() + 1)
      this.years = str;
      
    },
    // 获取首页其他列表
    getHomeList() {
        getAds('home_banner').then(res => {
            this.homeBannerList = res.data;
        })
        getPage('list-2', { pageIndex: 1, pageSize: 9 }).then(res => {
            this.newsList = [];
            let list1 = res.data.slice(0, 3);
            let list2 = res.data.slice(3, 6);
            let list3 = res.data.slice(6, 9);
            if(list1.length > 0) {
                this.newsList[0] = list1;
                if(list2.length > 0) {
                    this.newsList[1] = list2;
                    if(list3.length > 0) {
                        this.newsList[2] = list3;
                    }
                }
            }
        });
        getPage('list-1', { pageIndex: 1, pageSize: 4 }).then(res => {
            this.activeList = res.data;
        });
        getAds('school_banner', { pageIndex: 1, pageSize: 9 }).then(res => {
            this.schoolBannerList = res.data;
        });
        getAds('home_study', { pageIndex: 1, pageSize: 5 }).then(res => {
            this.homeStudyList = res.data;
            this.homeStudyList0 = res.data[0];
            this.homeStudyList1 = res.data[1];
            this.homeStudyList2 = res.data[2];
            this.homeStudyList3 = res.data[3];
            this.homeStudyList4 = res.data[4];
        });
    },
    // 首页进行跳转
    handleDetail(url) {
        if(url) {
            if(url.indexOf('http') === 0) {
                window.open(url);
            }else{
                this.$router.push({ path: url })
            }
        }
    },
    getday (year, month) {
        let day = new Date(year, month, 0);
        this.dateHtml(day.getDate(), year, month)
    },
    dateHtml(index, year, month) {
        this.dateList = [];
        for (let i = 0; i < index; i++) {
            let d = year + '-' + month + '-' + (i + 1);
            if (d == this.date) {
                this.currentDate = i + 1;
            } 
            this.dateList.push(i+1)
        }
    },
    addBtn() {
      this.redAdd(true);
    },
    reductionBtn() {
      this.redAdd(false);
    },
    redAdd(flase) {
        let dates = this.years.replace('年', "-").replace('月', '')
        let currentDate = new Date(dates);
        let lastDate = currentDate.setMonth(flase?(currentDate.getMonth() + 1):(currentDate.getMonth() -1));
        lastDate = new Date(lastDate);
        let lastYear = lastDate.getFullYear() + "年";
        let lastMonth = (lastDate.getMonth() + 1) + "月";
        this.getday(lastDate.getFullYear(), lastDate.getMonth() + 1)
        this.years = lastYear + lastMonth;
    }
  },
}
</script>

<style scoped src="@/assets/styles/style.css"></style>
<style scoped src="@/assets/styles/home.css"></style>

